<template>
  <div id="vacationRequestView">
    <div class="row">
      <div class="col">
        <h4>review cash-in-lieu requests</h4>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
        <ul class="error">
          <li v-for="error in errors">
            {{ error.message }}
          </li>
        </ul>
        <app-modal id="selectedRequestModal" size="large" v-if="showSelectedRequest" :static="false" :show="showSelectedRequest" :closeOnEscape="true" title="view request" :closeModalEvent="closeViewModalEvent">
          <div>
            <EmployeeInfoDisplay :employeeInfo="selectedRequestToView.employeeInfo" :vacationYear="selectedRequestToView.vacationMaster.vacationYear"></EmployeeInfoDisplay>
          </div>
          <hr />
          <RequestView :request="selectedRequestToView"></RequestView>
          <template v-slot:footer>
            <hr />
            <div class="col-md-12 text-center">
              <h5>Action/Log History Details</h5>
              <RequestActionList :requestActions="selectedRequestToView.requestActions"></RequestActionList>
            </div>
          </template>
        </app-modal>
        <app-modal :show="showApprovalOrRejectionModal" :static="true" size="medium" :title="selectedAction.description + ' requests'">
          <form id="actionForm" onsubmit="event.preventDefault();">
            <fieldset v-bind:disabled="isActioningRequests">
              <div class="form-group row mt-2">
                <!--<span class="font-weight-bold">Are you sure you want to {{ selectedAction.description }} this requst?</span>-->
                <span class="font-weight-bold">comment</span>
                <textarea v-model="actionComment" rows="5" class="form-control" v-bind:required="selectedAction !== actions.Approve"></textarea>
                <div class="invalid-feedback">
                  Please enter comments for this action.
                </div>
              </div>
            </fieldset>
          </form>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="onDismissAction" v-bind:disabled="isActioningRequests">No</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isActioningRequests }" @click.prevent="onActionRequestsConfirmed" v-bind:disabled="isActioningRequests">Yes<span class="spinner"></span></button>
          </template>
        </app-modal>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <data-table v-if="requests.length" id='cashInLieu-requests-table' :headers='tableHeaders' :items='requests'>
          <template v-slot:item.employeeName="{ item }">
            <!--<router-link :to="{ name: 'viewCashInLieuRequest', params: { id: item.id }}">
              {{ item.employeeName }}
            </router-link>-->
            <a href="#" @click="openSelectedRequest(item)">{{ item.employeeName }}</a>
          </template>
          <template v-slot:item.isChecked="{ item }">
            <input type="checkbox" v-model="item.isChecked" v-bind:disabled="shouldDisableRequest(item)" />
          </template>
          <template v-slot:item.requestDate="{ item }">
            {{ item.requestDate | toShortDateString }}
          </template>
          <template v-slot:item.leaveType="{ item }">
            {{ item.leaveType | toLeaveTypeDescription }}
          </template>
        </data-table>
        <div v-else-if="employeeInfo.employeeId" class="font-weight-bold">
          There are no cash in lieu requests awaiting your action
        </div>
      </div>
      <div class="mt-4 col-md-12 text-center">
        <button class="btn btn-primary d-inline mr-2" v-if="showApproveButton" @click="approveRequests" title="Approve requests">Approve &#x2713;</button>
        <button class="btn btn-danger d-inline mr-2" v-if="showCancelButton" @click="cancelRequests" title="Cancel request">Cancel &#x2716;</button>
        <button class="btn btn-danger d-inline mr-2" v-else-if="showRejectButton" @click="rejectRequests" title="Reject request">Reject &#x2716;</button>
        <button class="btn btn-warning d-inline mr-2" v-if="showReturnButton" @click="returnRequests" title="Return request">Return &#x23CE;</button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .action-btn {
    cursor: pointer;
    font-size: 30px;
  }

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }
</style>

<style lang="scss">

  #selectedRequestModal {
    height: 90%;
  }

  #selectedRequestModal .modal-body-footer-container {
    height: 90%;
    overflow-y: auto
  }

  /*#selectedRequestModal > .modal-header {
    height: 10%
  }

  #selectedRequestModal > .modal-body {
    height: 50%;
    overflow-y: auto
  }

  #selectedRequestModal > .modal-footer {
    height: 35%;
    overflow-y: auto
  }*/

</style>

<script>
  import store from '@/store'
  import { mapState } from 'vuex'

  import { employeeService, cashInLieuRequestService, } from '@/services'

  import Enums from '@/utils/enums'
  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'
  import { CLOSE_MODAL_EVENT } from '@/utils/constants'

  import { toLeaveTypeDescription } from '@/filters/enum'
  import { toShortDateString } from '@/filters'

  import EmployeeInfoDisplay from '@/components/cashInLieu/ViewEmployeeInfoDisplay'
  import DataTable from '@/components/ui/DataTable'
  import RequestView from '@/components/cashInLieu/RequestView'
  import RequestActionList from '@/components/leaveRequests/RequestActionList'

  import GeneralMixin from '@/mixins/GeneralMixin'
  import AccessControlMixin from '@/mixins/AccessControlMixin'
  import LeaveRequestMixin from '@/mixins/leaveRequest'
  import LeaveRequestActionsMixin from '@/mixins/leaveRequest/requestActions'


  export default {
    name: 'CashInLieuRequestReviewView',
    components: {
      EmployeeInfoDisplay,
      DataTable,
      RequestView,
      RequestActionList
    },
    mixins: [GeneralMixin, AccessControlMixin, LeaveRequestMixin, LeaveRequestActionsMixin],
    data: () => ({
      requests: [],
      employeeInfo: {
        employeeId: '',
        employeeNo: '',
        fullName: '',
      },
      tableHeaders: [
        {
          name: 'isChecked',
          title: ' '
        },
        {
          name: 'employeeName',
          title: 'employee name'
        },
        {
          name: 'requestDate',
          title: 'request date'
        },
        {
          name: 'requestType',
          title: 'request type'
        },
        {
          name: 'numberOfDays',
          title: 'number of days'
        }
      ],
      showApprovalOrRejectionModal: false,
      showSelectedRequest: false,
      isActioningRequests: false,
      selectedRequestToView: null,
      closeViewModalEvent: 'CLOSE_VIEW_MODAL'
    }),
    computed: {
      ...mapState({
        appSettings: state => state.applicationSettingsModule.appSettings,
      }),
      checkedRequests() {
        return this.requests.filter(request => request.isChecked)
      },
      showCancelButton() {
        return (this.checkedRequests.length > 0 && (this.checkedRequests.length == this.checkedRequests.filter(request => this.canCancelRequest(request)).length))
      },
      showApproveButton() {
        return (this.checkedRequests.length > 0 && (this.checkedRequests.length == this.checkedRequests.filter(request => this.canApproveRequest(request)).length))
      },
      showRejectButton() {
        return (this.checkedRequests.length > 0 && (this.checkedRequests.length == this.checkedRequests.filter(request => this.canRejectRequest(request)).length))
      },
      showReturnButton() {
        return (this.checkedRequests.length > 0 && (this.checkedRequests.length == this.checkedRequests.filter(request => this.canReturnRequest(request)).length))
      }
    },
    filters: {
      toShortDateString,
      toLeaveTypeDescription
    },
    methods: {
      shouldDisableRequest(request) {
        return request.status != Enums.RequestStatus.Submitted.value && request.status != Enums.RequestStatus.Edited.value
      },
      resetData() {
        this.employeeInfo.employeeId = ''
        this.employeeInfo.employeeNo = ''
        this.employeeInfo.fullName = ''
        this.requests.splice(0, this.requests.length)
      },
      fetchRequests(employeeId) {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, '')
        let tasks = []
        tasks.push(employeeService.getEmployee(employeeId))
        tasks.push(cashInLieuRequestService.getRequestsPendingUserAction(employeeId))
        Promise.all(tasks).then((results) => {
          self.resetData()
          const employee = results[0].data
          const requests = results[1].items
          self.employeeInfo.employeeId = employee.employeeId
          self.employeeInfo.employeeNo = employee.employeeNo
          self.employeeInfo.fullName = employee.fullName
          if (requests.length) {
            requests.forEach(request => {
              request.leaveType = request.requestType
              request.isChecked = (request.id == this.$route.params.id)
            })
            self.requests = [...requests]
          }
        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      openSelectedRequest(request) {
        this.selectedRequestToView = request
        this.showSelectedRequest = true
      },
      closeSelectedRequest() {
        this.selectedRequestToView = null
        this.showSelectedRequest = false
      },
      cancelRequests() {
        this.actionRequests(Enums.Actions.Cancel, Enums.RequestStatus.Cancelled)
      },
      approveRequests() {
        this.actionRequests(Enums.Actions.Approve, Enums.RequestStatus.Approved)
      },
      rejectRequests() {
        this.actionRequests(Enums.Actions.Reject, Enums.RequestStatus.Rejected)
      },
      returnRequests() {
        this.actionRequests(Enums.Actions.Return, Enums.RequestStatus.Returned)
      },
      actionRequests(action, nextRequestStatus) {
        this.selectedAction = action
        this.nextRequestStatus = nextRequestStatus
        this.showApprovalOrRejectionModal = true
      },
      onActionRequestsConfirmed() {
        const self = this
        const requestForm = document.getElementById('actionForm')
        if (requestForm.checkValidity() === false) {
          requestForm.classList.add('was-validated');
        }
        else {
          self.isActioningRequests = true
          store.dispatch(self.selectedAction.actionName, { requestIds: this.checkedRequests.map(a => a.id), requestType: Enums.RequestType.CashInLieuOfVacation.value, comment: self.actionComment })
            .then(() => {
              self.showSuccessMessage(`request ${self.selectedAction.postActionText} successfully`)
              this.checkedRequests.forEach(request => {
                request.status = self.nextRequestStatus
                request.isChecked = false
              })
              self.actionComment = null
              self.showApprovalOrRejectionModal = false
              self.selectedRequestToAction = null
            })
            .catch((error) => {
              self.showErrorMessage(error)
            }).finally(() => {
              self.isActioningRequests = false
            })
        }
      },
      onDismissAction() {
        this.actionComment = null
        this.showApprovalOrRejectionModal = false
      }
    },
    mounted() {
      eventBus.$on(this.closeViewModalEvent, this.closeSelectedRequest)
      eventBus.$on(CLOSE_MODAL_EVENT, this.onDismissAction)
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        if (vm.$route.params.employeeId) {
          vm.fetchRequests(vm.$route.params.employeeId)
        }
        else {
          vm.fetchRequests(vm.currentUser.employeeId)
        }
      })
    }
  }
</script>
